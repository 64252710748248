<template>
  <div>
    <div class="text--primary font-weight-bold text-h7">
      Create an account
    </div>
    <v-form ref="form" lazy-validation>
      <input
        v-model="register.name"
        class="cardElement mb-1 black--text"
        placeholder="Full Name*"
      />

      <input
        v-model="register.email"
        class="cardElement ma-0 black--text"
        placeholder="Email Address*"
        autocomplete="off"
      />

      <v-checkbox
        v-model="register.agreed_compliance_marketing"
        color="tertiaryGrey"
        light
        hide-details
      >
        <template v-slot:label>
          <div class="text-caption tertiaryGrey--text">
            <div>
              By signing up I agree to the WithU
              <a
                target="_blank"
                href="https://withutraining.com/privacy"
                @click.stop
                class="secondary--text"
              >
                EULA
              </a>
              ,
              <a
                target="_blank"
                href="https://withutraining.com/privacy"
                @click.stop
                class="mr-1 secondary--text"
              >
                Privacy Policy
              </a>
              {{ "& to receive occasional marketing emails." }}
            </div>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox
        v-if="dataSharing"
        v-model="register.agreed_data_sharing"
        color="secondary"
        dark
        hide-details
      >
        <template v-slot:label>
          <div class="text-caption grey--text text--lighten-2">
            <div v-if="dataSharing.handlebars">
              <component :is="dataSharingText"></component>
            </div>
            <div v-else>
              {{ dataSharing.copy }}
            </div>
          </div>
        </template>
      </v-checkbox>
      <v-alert
        :value="error != null"
        type="error"
        border="left"
        text
        dense
        class="ma-0 my-2 text-caption"
        style="max-width: 500px"
      >
        {{ error }}
      </v-alert>
      <v-btn
        color="primary"
        dark
        class="mt-6 white--text font-weight-bold text-subtitle-1"
        style="height: 40px; width: 100%;"
        :block="isMobile"
        :loading="loading || gettingDeal"
        @click="submit"
      >
        <span> Claim your offer </span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import rules from "@/rules.js";
import isEmail from "validator/es/lib/isEmail";

export default {
  components: {},

  data() {
    return {
      rules: rules,
      error: null,
      gettingDeal: false,
      register: {
        email: null,
        name: null,
        agreed_compliance_marketing: false,
        agreed_data_sharing: false,
      },
    };
  },
  watch: {
    "register.email": function (val) {
      if (val) {
        this.register.email = val.toLowerCase().trim();
        this.checkEmail();
      }
    },

    "register.name": function (val) {
      if (val) this.register.name = val.toUpperFirstLetter();
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      offering: "account/offering",
      originalLink: "account/originalLink",
      deal: "account/deal",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    loading() {
      return this.account.loading;
    },
    code() {
      return this.account.redeem.code;
    },

    successfulCode() {
      return this.deal != null && this.code != null;
    },
    dataSharing() {
      return this.deal ? this.deal.data_sharing : null;
    },
    dataSharingText() {
      var t = `<div>${this.dataSharing.copy}</div>`;

      var matches = this.dataSharing.copy.match(/{{.*}}/gm);

      for (var m of matches) {
        var key = m.replace("{{", "").replace("}}", "");
        var handlebar = this.dataSharing.handlebars[key];
        var text = handlebar.text;
        var url = handlebar.url;
        if (text != null && url != null) {
          t = t.replace(
            m,
            `<a target="_blank" href="${url}" @click.stop class="mr-1 secondary--text">${text}</a>`
          );
        } else if (text != null) {
          t = t.replace(m, text);
        }
      }

      return {
        template: t,
      };
    },
    hasName() {
      if (this.register.name == null || this.register.name == "") return;
      var arr = this.register.name
        .split(" ")
        .filter((n) => n != null && n != "");
      return arr.length > 1;
    },

    canSubmit() {
      var hasData =
        this.hasName &&
        this.register.email != null &&
        this.register.email != "" &&
        this.register.agreed_compliance_marketing == true;

      if (!hasData) {
        return false;
      }
      if (!isEmail(this.register.email.trim())) {
        return false;
      }
      if (this.dataSharing && this.dataSharing.is_required) {
        if (!this.register.agreed_data_sharing) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      createUser: "account/createUser",
      setLoading: "account/setLoading",
      getDeal: "account/getDeal",
    }),
    async checkEmail() {
      var email = this.register.email;
      if (isEmail(email)) {
        if (!this.deal) {
          var arr = email.split("@");
          if (arr.length > 1) {
            this.gettingDeal = true;
            await this.getDeal({ domain: arr[1] });
            this.gettingDeal = false;
          }
        }
      }
    },
    submitError() {
      if (!this.hasName) {
        return "Please enter your full name";
      }
      if (this.register.email == null || this.register.email == "") {
        return "Please enter your email address";
      }

      if (!isEmail(this.register.email.trim())) {
        return "Please provide a valid email address";
      }
      if (this.register.agreed_compliance_marketing != true) {
        return "Please agree to our terms and conditions to create an account";
      }

      if (this.dataSharing && this.dataSharing.is_required) {
        if (!this.register.agreed_data_sharing) {
          return "Please agree to the data sharing conditions to create an account";
        }
      }

      return null;
    },
    async submit() {
      if (this.loading || this.gettingDeal) {
        return;
      }
      if (this.$refs.form.validate()) {
        this.error = this.submitError();
        if (this.error) return;

        var payload = {
          ...this.register,
        };
        if (this.deal) {
          payload.deal_id = this.deal.id;
        }
        if (this.offering) {
          payload.offering = this.offering;
        }
        if (this.code) {
          payload.code = this.code;
        }
        if (this.originalLink) {
          payload.referringLink = this.originalLink;
        }

        await this.createUser(payload);
      }
    },
  },
  beforeMount() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
</style>

