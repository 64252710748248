<template>
  <div class="bar-container">
    <div class="text-container">
      <div class="primary1--text text-h6 font-weight-bold">Congratulations!</div>
      <div class="primary1--text text-body-2 ml-4">You’ve accessed an offer through {{partner}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {},
  props: {
    partner: String
  },
  data() {
    return {
    };
  },
  watch: {
    
  },
  computed: {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.bar-container {
    width: 100vw;
    height: 70px;
    background-color: rgba(122,104,130, 0.3)
}
.text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>

