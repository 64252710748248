<template>
  <div v-if="!loading && show" style="color: var(--v-secondary-base)">
    <span v-if="showThen" class="text-caption font-weight-medium">then </span>
    <span class="font-weight-bold">{{ amount }}</span>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      currency: "USD",
      loading: true,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
      products: "account/products",
      deal: "account/deal",
      offering: "account/offering",
    }),
    show() {
      return this.offering != "free_lifetime";
    },
    showThen() {
      if (!this.freeTrial) {
        return false;
      }

      var str = `${this.freeTrial} free access to WithU`;

      if (this.deal) {
        var subscription = this.deal.subscription;
        if (subscription && subscription.discount_percent) {
          if (str) {
            return false;
          }
        }
      }
      return true;
    },
    product() {
      return this.products[1];
    },
    price() {
      return this.product.prices.find((p) => p.currency == this.currency);
    },
    discountPercent() {
      if (this.deal) {
        var subscription = this.deal.subscription;
        if (subscription && subscription.discount_percent) {
          return subscription.discount_percent;
        }
      }
      return 0;
    },
    amount() {
      const price = this.price.price;
      var discount = price * (this.discountPercent / 100);
      var newPrice = price - discount;
      return `${this.price.symbol}${newPrice.toFixed(2)}/${this.product.term}`;
    },
  },
  methods: {
    async init() {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const location = await response.json();

        if (location && location.currency) {
          const c = location.currency;
          if (c == "USD" || c == "GBP" || c == "EUR") {
            this.currency = c;
          }
        }

        this.loading = false;
      } catch (e) {
        // console.log((e);
        this.loading = false;
      }
    },
  },

  beforeMount() {
    this.init();
  },
};
</script>

<style>
</style>