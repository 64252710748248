<template>
  <div>
    <v-snackbar v-model="refreshSnack" :timeout="timeout" top>
      {{ refreshSnackWithBtnText }}
      <template v-slot:action="{}">
        <v-btn dark text color="#00f500" @click.native="refreshApp">{{
          refreshSnackBtnText
        }}</v-btn>
        <v-btn icon @click="refreshSnack = false">
          <font-awesome-icon
            :icon="timesIcon"
            size="lg"
            :style="{ color: 'white' }"
          />
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColour"
      :timeout="snackbarTimeout"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      :multi-line="this.$vuetify.breakpoint.xs"
      v-model="uploadingSnackbar"
      color="secondary"
      :timeout="-1"
    >
      {{ uploadingSnackbarMessage }}
      <v-progress-linear
        color="white"
        class="ma-3"
        :indeterminate="true"
      ></v-progress-linear>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      //versioning
      timesIcon: faTimes,
      refreshing: false,
      registration: null,
      refreshSnackBtnText: "REFRESH",
      refreshSnackWithBtnText: "New update available",
      refreshSnack: false,
      timeout: -1,
      //data
      snackbar: false,
    };
  },
  watch: {
    alertSnackbar(val) {
      if (val) {
        this.snackbar = true;
      } else {
        this.snackbar = false;
      }
    },
    snackbar(val) {
      if (!val) {
        this.clearAlert();
      }
    },
  },

  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    alertSnackbar() {
      return this.alert.message ? true : false;
    },
    snackbarColour() {
      return this.alert.type;
    },
    snackbarTimeout() {
      return this.alert.timeout;
    },
    snackbarMessage() {
      return this.alert.message;
    },
    uploadingSnackbar() {
      return this.alert.uploading;
    },
    uploadingSnackbarMessage() {
      return this.alert.uploadingMessage;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      clearUploading: "alert/clearUploading",
    }),
    async init() {
      this.clearAlert();
      this.clearUploading();
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.refreshSnackBtnText = "Refresh";
      this.refreshSnackWithBtnText = "New version available!";
      this.refreshSnack = true;
    },
    refreshApp() {
      this.refreshSnack = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
  mounted() {
    this.init();
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
};
</script>
