<template>
  <div>
    <v-img
      v-if="logo && showDeal"
      alt="Partner Logo"
      :src="logo"
      :max-height="small ? '120' : '250'"
      :max-width="small ? '120' : '250'"
      style="border-radius: 10px"
    />
    <v-img
      v-else-if="!hideIfNoDeal"
      alt="WithU Logo"
      src="@/assets/withu-logo.png"
      :max-height="small ? '30' : '60'"
      :max-width="small ? '100' : '200'"
      style="border-radius: 10px blue"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    showDeal: {
      type: Boolean,
      default: false,
    },
    hideIfNoDeal: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      deal: "account/deal",
    }),

    logo() {
      if (this.deal && this.deal.web && this.deal.web.logo) {
        return this.deal.web.logo;
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>