<template>
  <v-container class="pa-0">
    <div v-if="initLoading || hasLinkAndAdblock">
      <v-row no-gutters>
        <v-col>
          <div style="margin-top: 25vh">
            <loading-component v-if="initLoading" loading-component />
            <div
              v-else-if="hasLinkAndAdblock"
              style="max-width: 80vw; margin: 0 auto"
            >
              <v-alert type="error" border="left" text dense class="ma-0 my-2">
                Looks like you are trying to unlock a special offer but your
                Adblock is currently turned on. Please turn off your adblock and
                hit: Try again
              </v-alert>
              <div class="d-flex justify-center">
                <v-btn
                  color="primary"
                  rounded
                  light
                  class="white--text"
                  @click="reload"
                >
                  Try again
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="!isDesktop">
        <div v-if="canEnterCode && canRedeemCode">
          <v-img
            class="redeem-header"
            position="top right"
            height="65vh"
            src="@/assets/header.png"
            lazy-src="@/assets/header-lo.png"
            :eager="true"
          />
          <v-img
            class="partner-title"
            src="@/assets/sf-redeem-title.png"
          ></v-img>
          <partner-bar
            class="partner-bar"
            :logo-path="require('@/assets/sf-logo.png')"
          />
          <div class="content-container">
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <div class="redeem-container-mobile">
                  <redeem-code
                    v-if="canRedeemCode && canEnterCode"
                    :enteringCode="enteringCode"
                    v-on:toggle="toggleEnteringCode"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="bullets-container-mobile">
              <v-col>
                <div
                  v-for="(bullet, index) in bullets"
                  :key="index"
                  class="bullet-row py-2 d-flex align-center"
                >
                  <div class="bullet-image-mobile">
                    <v-img
                      :alt="bullet.icon"
                      class="mr-2"
                      :src="getImgUrl(bullet)"
                      height="60"
                      lazy-src="@/assets/bullet-3-lo.png"
                      contain
                      :eager="true"
                    />
                  </div>
                  <div class="pl-5 primary1--text body-1">
                    {{ bullet.text }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-img
                  contain
                  class="footer-image"
                  src="@/assets/mobile-footer.png"
                  lazy-src="@/assets/mobile-footer-lo.png"
                  max-width="75vw"
                  :eager="true"
                />
              </v-col>
            </v-row>
            <div  class="mb-16"></div>
          </div>
        </div>
        <div v-else>
          <v-container >
            <v-row align="start">
              <v-col cols="10" offset="1">
                <v-img class="mt-6" src="@/assets/sf-unlocked-mobile.png" />
                <div
                  v-if="existingUser"
                  class="mt-4 d-flex flex-column text-center"
                  style="max-width: 450px"
                >
                  <existing-user />
                </div>
                <div v-else>
                  <deal v-if="hasDeal"></deal>
                  <div class="mb-10"></div>
                  <account v-show="!hideAccount" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <div v-else>
        <v-row no-gutters align="start">
          <v-col class="mt-6" cols="6">
            <div v-if="canRedeemCode && canEnterCode">
              <v-img
                contain
                class="center-in-col"
                max-width="210"
                src="@/assets/rating.png"
              />
              <v-img
                contain
                class="desktop-image-main"
                src="@/assets/desktop-left.png"
                lazy-src="@/assets/desktop-left-lo.png"
                max-width="40vw"
              />
              <v-img
                contain
                class="center-in-col"
                max-width="165"
                src="@/assets/sf-partnered.png"
              />
            </div>
            <div v-else>
              <v-img contain height="300px" src="@/assets/desktop-deal-left.png" />
              <v-img
                contain
                class="center-in-col"
                max-width="165"
                src="@/assets/sf-unlocked.png"
              />
            </div>
          </v-col>
          <v-col cols="6" class="mt-6" style="padding: 0 50px">
            <div class="right-hand-col">
              <progress-indicator
                class="mb-10 center-in-col"
                :steps="4"
                :currentStep="getStep()"
              ></progress-indicator>
              <div v-if="canEnterCode && canRedeemCode">
                <div class="text-h5 primary--text font-weight-bold text-center">
                  World class audio fitness coaching.
                </div>
                <div class="text-h6 secondaryGrey--text font-weight-bold mb-10 text-center">
                  Train anywhere. Cancel anytime.
                </div>
                <v-row>
                  <v-col
                    v-for="(bullet, index) in bullets"
                    :key="index"
                    cols="4"
                  >
                    <div class="bullet-image">
                      <v-img
                        :alt="bullet.icon"
                        :src="getImgUrl(bullet)"
                        max-width="60"
                        min-width="60"
                        contain
                      />
                    </div>
                    <div class="bullet-text caption">
                      {{ bullet.text }}
                    </div>
                  </v-col>
                </v-row>
                <div class="redeem-container">
                  <redeem-code
                    v-if="canRedeemCode && canEnterCode"
                    :enteringCode="enteringCode"
                    v-on:toggle="toggleEnteringCode"
                  />
                </div>
              </div>
              <div v-else>
                <div
                  v-if="existingUser"
                  class="d-flex flex-column text-center"
                  style="max-width: 450px"
                >
                  <existing-user />
                </div>
                <div v-else>
                  <deal v-if="hasDeal"></deal>
                  <div class="mb-10"></div>
                  <account v-show="!hideAccount" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import deal from "@/components/deal";
import price from "@/components/price";
import account from "./account";
import redeemCode from "./code";
import existingUser from "./existingUser";
import partnerBar from "@/components/partner-bar";
import progressIndicator from "@/components/progress-indicator";
import { bus } from "@/main";

export default {
  name: "Home",
  components: {
    deal,
    account,
    redeemCode,
    price,
    existingUser,
    partnerBar,
    progressIndicator,
    bus,
  },
  data() {
    return {
      error: false,
      hideAccount: false,
      enteringCode: false,
      canEnterCode: false,
      headerImageHeight: null,
    };
  },
  watch: {
    canRedeemCode(val) {
      if (!val) {
        this.enteringCode = false;
        this.hideAccount = false;
      }
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      alert: (state) => state.alert,
    }),
    ...mapGetters({
      hasDeal: "account/hasDeal",
      freeTrial: "account/freeTrial",
      existingUser: "account/existingUser",
      canRedeemCode: "account/canRedeemCode",
      deal: "account/deal",
    }),
    initLoading() {
      return this.account.initLoading;
    },
    loading() {
      return this.account.loading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    hasLinkAndAdblock() {
      return this.account.redeem.adblock;
    },
    description() {
      return this.enteringCode
        ? "Enter your code to access WithU"
        : this.freeTrial
        ? `${this.freeTrial} free access to WithU`
        : "Get started";
    },
    bullets() {
      var b = [
        {
          icon: "bullet-1",
          text: "1000+ on-demand audio workouts",
        },
        {
          icon: "bullet-2",
          text: "Try HIIT, Strength, Yoga, Running & more",
        },
        {
          icon: "bullet-3",
          text: "Weekly new content drops",
        },
      ];
      return b;
    },
  },
  methods: {
    getImgUrl(pic) {
      if (pic.img) {
        return pic.img;
      } else {
        return require("@/assets/" + pic.icon + ".png");
      }
    },
    reload() {
      window.location.reload();
    },
    toggleEnteringCode(val) {
      this.enteringCode = val;
      if (this.hideAccount) {
        this.hideAccount = false;
      }
    },
    getStep() {
      if (this.canRedeemCode && this.canEnterCode) {
        return 1;
      }
      return 2;
    },
  },
  mounted() {
    // this.$gtm.trackEvent({
    //   event: "RegistrationStart",
    // });
  },
  beforeMount() {
    if (this.canRedeemCode) {
      this.enteringCode = this.$route.path == "/redeem";
      this.canEnterCode = this.$route.path == "/redeem";
      this.hideAccount = this.enteringCode;
    }
  },
  beforeDestroy() {

  },
};
</script>

<style scoped>
.redeem-header {
  position: relative;
  margin-top: -90px;
  right: 0;
  z-index: 0;
}
.partner-title {
  max-width: 205px;
  position: absolute;
  bottom: 45%;
  left: 10px;
}
.partner-bar {
  position: absolute;
  bottom: 35%;
  z-index: 1;
}
.content-container {
  position: absolute;
  left: 0;
  top: 65%;
}
.redeem-container-mobile {
  width: 100vw;
  padding: 20px;
}

.bullets-container-mobile {
  /* width: 100vw; */
  background-color: rgba(65, 66, 71, 0.2);
}
.bullet-row {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 80%;
}
.bullet-image-mobile {
  width: 60px;
  height: 60px;
  margin: auto 0 auto 40px;
}
.footer-image {
  margin: 40px auto;
}

.desktop-image-main {
  margin: 40px;
}

.center-in-col {
  justify-content: center;
  margin: 0 auto;
}
.bullet-image {
  height: 100px;
  width: 60px;
  margin: 0 auto;
}
.bullet-text {
  word-wrap: break-word;
  text-align: center;
  color: #707070;
}
.redeem-container {
  padding: 20px;
}
.right-hand-col {
  margin: 0 auto;
  max-width: 500px;
}
</style>

