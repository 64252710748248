import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
export const bus = new Vue();

import Page from '@/components/page';
Vue.component('page', Page);

import Logo from '@/components/logo';
Vue.component('logo', Logo);

import moment from 'moment';
Vue.prototype.moment = moment;

window.axios = require('axios');
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch('alert/error', error.response);
    return Promise.reject(error);
  }
);

axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_API_KEY;

Vue.config.productionTip = false;

import VueMask from 'v-mask';
Vue.use(VueMask);

import loadingComponent from '@/components/loading';
Vue.component('loading-component', loadingComponent);

String.prototype.toUpper = function() {
  return this.toLowerCase().replace(/^\w|\s\w/g, function(letter) {
    return letter.toUpperCase();
  });
};

String.prototype.toUpperFirstLetter = function() {
  return this.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  // stripeAccount: 'acct_1GXWcRLdUrrOSabi',
  apiVersion: '2020-08-27',
  locale: 'auto',
};

// Vue.use(StripePlugin, options);

import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
  id: 'GTM-TVCGGJ4', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

import firebase from 'firebase/app';
import 'firebase/analytics';

var devFirebaseConfig = {
  apiKey: 'AIzaSyC6PGfDkvmcuSpil7fxtEqjwMbM7LAwhQ0',
  authDomain: 'vpt-dev-6deec.firebaseapp.com',
  databaseURL: 'https://vpt-dev-6deec.firebaseio.com',
  projectId: 'vpt-dev-6deec',
  storageBucket: 'vpt-dev-6deec.appspot.com',
  messagingSenderId: '676629632747',
  appId: '1:676629632747:web:c39b5d014c928ec88d50ae',
  measurementId: 'G-NCLHCBW80D',
};
var prodFirebaseConfig = {
  apiKey: 'AIzaSyDIIEQ8G9HErUMV6Pc6BsCY7ZbA2kE2bM0',
  authDomain: 'withu-d0318.firebaseapp.com',
  databaseURL: 'https://withu-d0318.firebaseio.com',
  projectId: 'withu-d0318',
  storageBucket: 'withu-d0318.appspot.com',
  messagingSenderId: '455093896444',
  appId: '1:455093896444:web:e03f9319759ae09d5f8379',
  measurementId: 'G-BJVYN8FMZ3',
};
firebase.initializeApp(prodFirebaseConfig);
firebase.analytics();
