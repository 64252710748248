<template>
  <div class="progress-container">
    <div class="progress-container" v-for="i in parseInt(steps)" :key="i">
      <div
        :class="
          i <= currentStep ? 'progress-circle complete-fill' : 'progress-circle'
        "
      ></div>
      <div
        v-if="i < steps"
        :class="i < currentStep ? 'progress-bar complete-fill' : 'progress-bar'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Number,
    currentStep: Number,
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.progress-container {
  display: flex;
  flex-direction: row;
}
.progress-circle {
  height: 18px;
  width: 18px;
  background-color: #bcbcbc;
  border-radius: 50%;
  display: inline-block;
}
.progress-bar {
  width: 40px;
  height: 2px;
  margin-top: 8px;
  background-color: #bcbcbc;
}
.complete-fill {
  background-color: #0d002d;
}
</style>