import { service } from '../../../service';
import { router } from '../../../router';
import { v4 as uuidv4 } from 'uuid';

import firebase from 'firebase/app';
import 'firebase/auth';

const state = {
  loading: false,
  initLoading: false,
  error: null,
  redeem: false,
  analytics: {
    anonymous_id: uuidv4(),
    user_id: null,
    email: null,
  },
  redeem: {
    adblock: null,
    data: null,
    code: null,
    deal: null,
    verifiedAt: null,
  },
  register: {
    existingUser: false,
    name: null,
    email: null,
    agreed_compliance_marketing: true,
    agreed_data_sharing: false,
    deal_id: null,
    offering: null,
    uid: null,
    user_id: null,
    currency: null,
    user: null,
  },
  download: {
    link: null,
  },
  stripe: null,
};

const actions = {
  //EVENTS
  page({ commit, state }, { name, properties }) {
    const url = '/analytics';

    const payload = {
      ...state.analytics,
      type: 'page',
      name,
      properties,
    };

    return service.post(url, payload);
  },
  events({ commit, state }, { event, properties }) {
    const url = '/analytics';

    const payload = {
      ...state.analytics,
      type: 'event',
      event,
      properties,
    };

    return service.post(url, payload);
  },
  setTimeout({ commit, state }, branchMatchId) {
    commit('setTimeout', branchMatchId);
    if (state.redeem.adblock) {
      dispatch('events', {
        event: 'Adblock Detected',
        properties: {},
      });
    }
  },
  async setBranchData({ commit, dispatch, state }, data) {
    //TO DO: check expiry
    commit('setBranchData', data);

    var id;
    var offering;
    var referringLink;
    if (data && data.data_parsed && data.data_parsed['~id']) {
      id = data.data_parsed['~id'];
      referringLink = data.data_parsed['~referring_link']
      offering = data.data_parsed['offering'];
      dispatch('events', {
        event: 'Web Link Opened',
        properties: data.data_parsed,
      });
    }
    // id = 'test';
    // referringLink = 'https://download.withu.fit/e/tACe2FlwDgb'
    // offering = 'withu_promotional1';

    if (!id && !referringLink) {
      commit('setInitLoading', false);
    } else {
      const url = `/getDeal`;

      const payload = {
        ...state.analytics,
        linkId: null,//id,
        offering,
        referringLink,
      };

      console.log(payload)

      commit('clearDeal');

      return service
        .post(url, payload)
        .then((result) => {
          // console.log(result);
          commit('setCode', null);
          commit('setDeal', result);

          router.push('/redeem').catch((err) => { });
        })
        .catch((err) => {
          // console.log(err);
          commit('setInitLoading', false);
          return;
        });
    }
  },

  async getDeal({ commit, state }, payload) {
    const url = `/getDeal`;

    const p = {
      ...state.analytics,
      ...payload,
    };

    return service
      .post(url, p)
      .then((result) => {
        commit('setDeal', result);
        router.push('/redeem').catch((err) => { });
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  },

  async verifyCode({ commit, state }, code) {
    const url = '/verifyRedemptionCode';

    const payload = {
      ...state.analytics,
      code,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit('setCode', result.code);
        commit('setDeal', result.deal);
        return result;
      })
      .catch((err) => {
        // console.log((err);
        return;
      });
  },

  async register({ commit, state }, p) {
    commit('setLoading', true);

    const url = '/webLogin';

    const payload = {
      ...state.analytics,
      ...p,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit('setRegister', payload);
      })
      .catch((err) => {
        commit('setLoading', false);
        return;
      });
  },

  async createUser({ commit, state, dispatch }, p) {
    commit('setLoading', true);

    const url = '/createWebUser';

    const payload = {
      ...state.analytics,
      ...p,
    };

    // // console.log((payload);

    return service
      .post(url, payload)
      .then((result) => {
        return dispatch('account/signIn', result, { root: true });
      })
      .catch((err) => {
        commit('setLoading', false);
        // console.log((err.response);
        if (err.response && err.response.status == 409) {
          commit('setExistingUser', true);
          commit('setEmail', p.email);
          return false;
        }
        return true;
      });
  },

  async stripeSession({ }, customer) {
    const url = '/stripeSession';

    return service.post(url, { customer }).catch((err) => {
      return null;
    });
  },

  async verifyLoginCode({ commit, state, dispatch }, { payload, manage }) {
    commit('setLoading', true);

    const url = '/verifyWebLoginCode';

    const p = {
      ...state.analytics,
      ...payload,
    };

    return service
      .post(url, p)
      .then((result) => {
        if (manage) {
          commit('setUser', result.user);
          commit('setLoading', false);

          return false;
        } else {
          return dispatch('account/signIn', result, { root: true });
        }
      })
      .catch((err) => {
        commit('setLoading', false);
        return true;
      });
  },

  async webSignIn({ commit, state, getters }, email) {
    commit('setLoading', true);

    const url = '/signInWeb';

    const payload = {
      ...state.analytics,
      email,
    };

    return service
      .post(url, payload)
      .then((result) => {
        commit('setLoading', false);
        return false;
      })
      .catch((err) => {
        commit('setLoading', false);
        return true;
      });
  },

  async signIn({ commit, state, getters, dispatch }, result) {
    console.log(result);

    if (result.signInLink) {
      return firebase
        .auth()
        .signInWithEmailLink(result.email, result.signInLink)
        .then((userCredential) => {
          // console.log((result, 'firebase sign in link');
          commit('setUser', result.user);

          var requiresPayment = true;

          if (
            result.user.revenueCatUser &&
            result.user.revenueCatUser.subscriber &&
            result.user.revenueCatUser.subscriber.entitlements &&
            result.user.revenueCatUser.subscriber.entitlements.withu
          ) {
            var expiresAt =
              result.user.revenueCatUser.subscriber.entitlements.withu
                .expires_date;
            const dt = new Date(expiresAt);
            if (Date.now() < dt) {
              requiresPayment = false;
            }
            // router.push('/manage').catch((err) => {});
            // return false;
          }

          var deal;
          if (getters.isValid) {
            deal = state.redeem.deal;
          }
          if (deal && deal.subscription) {
            if (
              deal.subscription.requires_payment == false ||
              (deal.subscription.offering &&
                deal.subscription.offering.startsWith('free_'))
            ) {
              requiresPayment = false;
            }
          } else if (
            result.user.offering &&
            result.user.offering.startsWith('free_')
          ) {
            requiresPayment = false;
          }
          if (requiresPayment) {
            router.push('/subscribe').catch((err) => { });
          } else {
            router.push('/download').catch((err) => { });
          }

          setTimeout(() => {
            dispatch('account/clearExistingUser', {}, { root: true });
          }, 2000);

          return false;
        })
        .catch((err) => {
          commit('setLoading', false);
          return true;
        });
    } else {
      commit('setLoading', false);
      return true;
    }
  },

  async downloadLink({ commit, state }) {
    commit('setLoading', true);

    const url = '/downloadLink';

    const payload = {
      email: state.register.email,
      createCode: false,
    };

    // // console.log((payload);

    return service
      .post(url, payload)
      .then((result) => {
        // console.log(result);
        commit('setDownload', result);
        return;
      })
      .catch((err) => {
        commit('setLoading', false);
        return;
      });
  },

  async sendText({ dispatch, commit, state }, p) {
    commit('setLoading', true);

    const url = '/sendText';

    const payload = {
      ...state.analytics,
      ...p,
    };

    return service
      .post(url, payload)
      .then((result) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  },

  async checkoutSession({ dispatch, commit, state }, priceId) {
    commit('setLoading', true);

    const url = '/checkoutSession';

    const payload = {
      ...state.analytics,
      priceId,
      dealId: state.register.deal_id,
      uid: state.register.uid,
      user_id: state.register.user_id,
      email: state.register.email,
    };

    return service.post(url, payload).then((result) => {
      return result.sessionId;
    });
  },

  async subscribe({ dispatch, commit, state }, subscribePayload) {
    const url = '/createStripeCustomerAndSubscription';

    const payload = {
      ...state.analytics,
      ...subscribePayload,
      deal_id: state.register.deal_id,
      uid: state.register.uid,
      user_id: state.register.user_id,
      email: state.register.email,
      name: state.register.name,
    };

    return service.post(url, payload).then((result) => {
      commit('setStripe', {
        paymentMethodId: payload.paymentMethodId,
        priceId: payload.priceId,
        ...result,
      });
      return result;
    });
  },

  setEmail({ commit }, email) { },

  setLoading({ commit }, loading) {
    commit('setLoading', loading);
  },
  setInitLoading({ commit }, loading) {
    commit('setInitLoading', loading);
  },
  clearExistingUser({ commit }) {
    commit('setExistingUser', false);
  },

  logout({ commit }) {
    commit('clearUser');
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
    state.error = null;
  },
  setInitLoading(state, loading) {
    state.initLoading = loading;
    state.error = null;
  },
  setTimeout(state, branchMatchId) {
    if (branchMatchId && state.redeem.data == null) {
      state.redeem.adblock = true;
    } else {
      state.redeem.adblock = false;
    }

    if (state.initLoading) {
      state.initLoading = false;
    }
  },
  setBranchData(state, data) {
    state.redeem.data = data;
    state.redeem.adblock = false;
    if (
      data.data_parsed != null &&
      data.data_parsed['+clicked_branch_link'] == true
    ) {
      console.log(data.data_parsed);
      state.redeem.verifiedAt = Date.now();
    }
  },
  setCode(state, code) {
    state.redeem.code = code;
  },
  setDeal(state, deal) {
    state.redeem.deal = deal;
    state.loading = false;
    state.initLoading = false;
    state.redeem.verifiedAt = Date.now();
  },
  clearDeal(state) {
    state.redeem.deal = null;
  },
  setRegister(state, register) {
    state.register = register;
  },
  clearUser(state) {
    state.register.user = null;
  },
  setUser(state, user) {
    // console.log(('set user', user);
    state.register.user_id = user.user_id;
    state.register.uid = user.firebase_id;
    state.register.email = user.email;
    state.register.name = user.name;
    state.register.deal_id = user.deal_id;
    state.register.user = user;

    state.analytics.user_id = user.user_id;
    state.analytics.email = user.email;

    state.stripe = null;

    window.Intercom('update', { user_id: user.user_id, email: user.email });
  },
  setDownload(state, resp) {
    state.download.link = resp.link;
    state.loading = false;
  },
  setEmail(state, email) {
    state.register.email = email;
  },
  setError(state, error) {
    state.error = error;
    state.loading = false;
  },
  setStripe(state, stripe) {
    state.stripe = stripe;
    // // console.log((stripe, 'stripe');
  },
  setExistingUser(state, val) {
    state.register.existingUser = val;
  },
};

const getters = {
  hasUser: (state) => {
    return state.register.uid != null && state.register.user_id != null;
  },
  existingUser: (state) => {
    return state.register.existingUser;
  },
  originalLink: (state) => {
    // return 'https://app.withutraining.com/e/tiKAKSuOCgb';
    var data = state.redeem.data;
    if (data && data.data_parsed && data.data_parsed['~referring_link']) {
      return data.data_parsed['~referring_link'];
    }
    return null;
  },
  isValid: (state) => {
    if (state.redeem && state.redeem.verifiedAt) {
      let minutes = 30;
      let minutesInMs = minutes * 60 * 1000;
      var minutesAgo = Date.now() - minutesInMs;
      const dt = new Date(minutesAgo).getTime();

      if (state.redeem.verifiedAt > dt) {
        return true;
      }
    }
    return false;
  },
  offering: (state, getters) => {
    if (getters.isValid) {
      if (
        state.redeem.deal &&
        state.redeem.deal.subscription &&
        state.redeem.deal.subscription.offering
      ) {
        return state.redeem.deal.subscription.offering;
      } else if (
        state.redeem.data &&
        state.redeem.data.data_parsed &&
        state.redeem.data.data_parsed['offering']
      ) {
        return state.redeem.data.data_parsed['offering'];
      }
    }
    return null;
  },
  deal: (state, getters) => {
    var redeem = state.redeem;
    if (!redeem.deal) {
      return null;
    }

    if (getters.isValid) {
      return redeem.deal;
    }
    return null;
  },
  hasDeal: (state, getters) => {
    return getters.deal != null;
  },
  canRedeemCode: (state, getters) => {
    return !(getters.offering || getters.hasDeal);
  },
  freeTrial: (state, getters) => {
    var str;
    if (state.redeem.deal && state.redeem.deal.subscription) {
      var subscription = state.redeem.deal.subscription;
      if (subscription.trial_days) {
        str = getters.daysToTime(subscription.trial_days)
      } else if (subscription.trial_days == 0) {
        return null;
      }
    }
    if (!str && getters.offering) {
      str = getters.offeringToTime(getters.offering);
    }

    if (!str) {
      str = '14 days';
    }

    return str;
  },

  offeringToTime: () => (offering) => {
    if (offering == 'free_daily') {
      return '1 day';
    } else if (offering == 'free_weekly') {
      return '7 days';
    } else if (offering == 'free_monthly') {
      return "1 month's";
    } else if (offering == 'free_two_month') {
      return '2 months';
    } else if (offering == 'free_three_month') {
      return '3 months';
    } else if (offering == 'free_six_month') {
      return '6 months';
    } else if (offering == 'free_yearly') {
      return "1 year's";
    } else if (offering == 'free_lifetime') {
      return "Lifetime's";
    } else {
      return null;
    }
  },
  daysToTime: () => (days) => {
    if (days == 1) {
      return `1 day`;
    } else if (days <= 14) {
      return `${days} days`;
    } else if (days <= 31) {
      return `1 month`;
    } else if (days <= 62) {
      return '2 months';
    } else if (days <= 93) {
      return '3 months';
    } else if (days <= 186) {
      return '6 months';
    } else if (days <= 365) {
      return '1 year';
    } else {
      return null;
    }
  },
  products: () => {
    return [
      {
        product_id: 'prod_JJWLQpeaiC5qbW',
        term: 'year',
        prices: [
          {
            price_id: 'price_1IgtJ1LdUrrOSabidKBy5FOC',
            currency: 'GBP',
            symbol: '£',
            price: 79.99,
            subunit: 7999,
          },
          {
            price_id: 'price_1IgtJ1LdUrrOSabiOsDCYCuz',
            currency: 'USD',
            symbol: '$',
            price: 79.99,
            subunit: 7999,
          },
          {
            price_id: 'price_1IgtJ1LdUrrOSabiG80pxkve',
            currency: 'EUR',
            symbol: '€',
            price: 79.99,
            subunit: 7999,
          },
        ],
      },
      {
        product_id: 'prod_JJWLQpeaiC5qbW',
        term: 'month',
        prices: [
          {
            price_id: 'price_1IgtJ1LdUrrOSabi78fJZp2W',
            currency: 'GBP',
            symbol: '£',
            price: 9.99,
            subunit: 999,
          },
          {
            price_id: 'price_1IgtJ1LdUrrOSabiJlC8jx68',
            currency: 'USD',
            symbol: '$',
            price: 9.99,
            subunit: 999,
          },
          {
            price_id: 'price_1IgtJ1LdUrrOSabibwyNL7Pp',
            currency: 'EUR',
            symbol: '€',
            price: 9.99,
            subunit: 999,
          },
        ],
      },
    ];
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
