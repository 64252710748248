<template>
  <div>
    <v-container>
      <div v-if="isDesktop">
        <v-row no-gutters class="align-center">
          <div class="primary--text h5 font-weight-bold text-center">
            {{ description }}
          </div>
          <div class="secondaryGrey--text h6 font-weight-bold text-center">
            {{ subtitle }}
          </div>
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters class="align-center">
          <div
            class="
              deal-full-width
              primary--text
              text-h5
              font-weight-bold
              text-center
            "
          >
            Congratulations!
          </div>
          <div
            class="
              deal-full-width
              primary--text
              text-h6
              font-weight-bold
              text-center
            "
          >
            You’ve qualified for:
          </div>
          <div
            class="
              deal-full-width
              secondaryGrey--text
              h6
              font-weight-bold
              text-center
            "
          >
            {{ description }}
          </div>
          <div
            class="
              deal-full-width
              primary--text
              h6
              font-weight-bold
              text-center
            "
          >
            to WithU!
          </div>
          <div class="mt-6 deal-full-width">
          <v-divider></v-divider>
          </div>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {},
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      freeTrial: "account/freeTrial",
      deal: "account/deal",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    logo() {
      if (this.deal && this.deal.web && this.deal.web.logo) {
        return this.deal.web.logo;
      } else {
        return null;
      }
    },
    title() {
      return this.deal.web && this.deal.web.title
        ? this.deal.web.title
        : "Congratulations";
    },
    type() {
      return this.deal.web && this.deal.web.type
        ? this.deal.web.type
        : "Partnership Deal";
    },
    subtitle() {
      return this.deal.web && this.deal.web.subtitle
        ? this.deal.web.subtitle
        : "See what you've unlocked below";
    },

    code() {
      return this.account.redeem.code;
    },

    description() {
      var str = "";
      if (this.freeTrial) {
        if (this.isDesktop) {
          str = `You’ve qualified for ${this.freeTrial} free subscription to WithU!`;
        } else {
          str = `${this.freeTrial} free subscription`;
        }
      }
      var subscription = this.deal.subscription;
      if (subscription && subscription.discount_percent) {
        if (str) {
          str += "<br/>then ";
        }
        str += `${subscription.discount_percent}% off your subscription`;
      }
      return str;
    },
  },
  methods: {},
};
</script>

<style scoped>
.deal-full-width {
  width: 100%;
}
</style>


