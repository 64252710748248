<template>
  <v-btn
    v-show="showBack"
    fab
    dark
    small
    color="transparent"
    class="mr-2"
    @click="handleBack('/')"
  >
    <font-awesome-icon
      :icon="arrowIcon"
      size="lg"
      :style="{ color: 'white' }"
    />
  </v-btn>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      arrowIcon: faArrowLeft,
    };
  },
  computed: {
    showBack() {
      return this.$route.name == "Subscribe";
    },
  },

  methods: {
    handleBack(fallback) {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>