import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0e002f',
        primary1: '#08001d',
        // secondary: '#01fac2',
        secondary: '#00ffc4',
        secondaryGrey: '#706882',
        tertiaryGrey: '#707070',
        accent: '#8f98c1',
        lightGrey: '#d0d0d0',
        grey2: '#bcbcbc',
        white: '#ffffff'
      },
    },
  },
});
