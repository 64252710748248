<template>
  <div style="max-width: 500px">
    <div class="text-h6 font-weight-medium">
      This email is already registered with a <strong>WithU</strong> account.
    </div>
    <div class="text-h6 font-weight-bold mt-5">
      We've sent a verification code to
    </div>
    <div class="text-title tertiaryGrey--text font-weight-medium">
      {{ registerEmail }}
    </div>

    <div class="text-body-2 mt-10 ml-1 mb-1 font-weight-bold">
      Enter verification code
    </div>
    <div class="input-wrapper mt-2">
      <PincodeInput v-model="code" />
    </div>
    <div class="d-flex justify-center" v-if="error">
      <v-alert
        type="error"
        border="left"
        text
        dense
        class="ma-0 mt-3"
        style="max-width: 300px"
      >
        Error verifying code
      </v-alert>
    </div>
    <div class="mt-10">
      <v-btn
        color="primary1"
        light
        class="mt-2 white--text font-weight-bold text-subtitle-1"
        style="height: 40px"
        :disabled="!canSubmit"
        :loading="loading"
        @click="submit"
        ><span class="px-10">Verify</span></v-btn
      >
    </div>
    <v-btn dark text class="mt-2" @click="resetUser">Cancel</v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PincodeInput from "vue-pincode-input";

export default {
  name: "Verify",
  components: {
    PincodeInput,
  },
  data() {
    return {
      code: "",
      error: null,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    loading() {
      return this.account.loading;
    },
    canSubmit() {
      return this.code && this.code.length == 4;
    },
    registerEmail() {
      return this.account.register.email;
    },
  },
  methods: {
    ...mapActions({
      verifyUser: "account/verifyLoginCode",
      setLoading: "account/setLoading",
      clearExistingUser: "account/clearExistingUser",
    }),
    resetUser() {
      this.clearExistingUser();
    },
    async submit() {
      this.error = null;

      var payload = {
        code: this.code,
        email: this.registerEmail,
      };
      // console.log((payload);
      var err = await this.verifyUser({ payload, manage: false });
      this.error = err;
      if (err) {
        this.code = "";
      }
    },
  },
  beforeMount() {
    this.setLoading(false);
  },
};
</script>

<style>
div.vue-pincode-input-wrapper {
  /* background-color: red; */
}

input.vue-pincode-input {
  /* background-color: white; */
  color: black;
  font-size: 30px;
  aspect-ratio: 1;
  border: 3px solid var(--v-secondary-base);
}
</style>

