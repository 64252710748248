<template>
<div>
  <deal-top-bar v-if="showDealBar" class="deal-bar" :partner="partner"></deal-top-bar>
  <div :class="showCenterLogo ? 'logo-centered mb-10' : 'logo-left mb-10'">
    <router-link to="/">
      <div v-if="showCenterLogo">
        <v-img
          alt="WithU Logo"
          contain
          src="@/assets/logo-center.png"
          height="65"
          position="center"
        />
      </div>
      <div v-else>
        <v-img
          alt="WithU Logo"
          class="shrink mr-2"
          contain
          src="@/assets/withu-logo-full-dark.png"
          height="30"
          position="center left"
        />
      </div>
    </router-link>
    <v-btn
      v-if="showLogout"
      color="secondary"
      dark
      class="mt-2 black--text font-weight-bold text-subtitle-1"
      style="height: 40px"
      @click="logout"
    >
      <span> Logout </span>
    </v-btn>
  </div>

  <!-- <div class="primary pa-3">
    <div :style="!mobile && 'padding-left:100px'">
      <v-img
        alt="WithU Logo"
        class="shrink mr-2"
        contain
        src="@/assets/withu-logo-full.png"
        transition="scale-transition"
        height="30"
        position="center left"
      />
    </div>
  </div> -->
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import back from "@/components/back";
import dealTopBar from "@/components/deal-top-bar";
import { bus } from "@/main";

export default {
  components: {
    back,
    dealTopBar
  },
  data() {
    return {

    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    user() {
      return this.account.register.user;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    showBarBackground() {
      return this.$route.name == "Subscribe";
    },
    showLogout() {
      return this.$route.name == "Manage" && this.user;
    },
    ...mapGetters ({
      hasDeal: "account/hasDeal",
      deal: "account/deal",
    }),
    showCenterLogo(){
      return this.hasDeal && !this.isDesktop;
    },
    partner() {
      return this.deal != null && this.deal.web !=null ? this.deal.web.partner : '';
    },
    showDealBar() {
      console.log(this.$route.name.toLowerCase());
      return this.partner != '' && this.isDesktop && this.$route.name.toLowerCase() == 'home';
    }
  },
  methods: {
    ...mapActions({
      logout: "account/logout",
    }),
  },
  mounted() {
    // bus.$on("updateLayout", (data) => {
    //   this.useCenterLogo = true;
    // });
  },
};
</script>
<style scoped>
.deal-bar {
  position: absolute;
  top: 0;
}
.logo-centered {
  position: relative;
  margin: 20px auto 0 auto;  
  width: 100%;
  z-index: 10;
}
.logo-left {
  position: relative;
  margin: 20px 0 0 20px;  
  width: 100%;
  z-index: 10;
}
</style>
