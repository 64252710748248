import Vue from 'vue';
import Router from 'vue-router';
import { store } from './store';

//**********AUTH************ */
// const Login = () => import('./views/user/login');
// const Register = () => './views/user/register';

//**********MAIN************ */
// const Home = () => import('@/views/home');
import Redeem from '@/views/redeem';
const Register = () =>
  import(/* webpackChunkName: "register" */ '@/views/register');
const Verify = () => import(/* webpackChunkName: "verify" */ '@/views/verify');
const Download = () =>
  import(/* webpackChunkName: "download" */ '@/views/download');
const Onboarding = () =>
  import(/* webpackChunkName: "onboarding" */ '@/views/onboarding');

//**********SUBSCRIBE************ */
const Subscribe = () =>
  import(/* webpackChunkName: "subscribe" */ '@/views/subscribe');
const SubscribeCancelled = () =>
  import(
    /* webpackChunkName: "subscribecancelled" */ '@/views/subscribe/cancelled'
  );
// const Manage = () => import(/* webpackChunkName: "managee" */ '@/views/manage');

Vue.use(Router);

const routes = [
  // {
  //   path: '/redeem',
  //   name: 'Redeem',
  //   component: Redeem,
  // },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
  },
  {
    path: '/cancelled',
    name: 'Subscribe Cancelled',
    component: SubscribeCancelled,
  },
  // {
  //   path: '/manage',
  //   name: 'Manage',
  //   component: Manage,
  // },
  {
    path: '*',
    name: 'Home',
    component: Redeem,
  },
];

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  var payload = {
    name: to.name,
    properties: {
      from: from.name,
      to: to.name,
      ...to.query,
    },
  };
  store.dispatch('account/page', payload);
});

router.beforeEach((to, from, next) => {
  var accountStore = store.state.account;
  var hasUser =
    accountStore.register.uid != null && accountStore.register.user_id != null;

  if (to.path == '/subscribe' || to.path == '/download') {
    if (!hasUser) {
      next('/redeem');
    }
  }

  if (to.path == "/"){
    next("/redeem");
  }
  next();
});
